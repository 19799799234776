import React from 'react'
import { graphql } from 'gatsby'
import styled from 'astroturf/react'

import { StoplightProject } from '@stoplight/elements-dev-portal'

import { MainLayout } from '../components/layout/main-layout'

const StoplightWrapper = styled('div')`
  @media screen and (max-width: 800px) {
    display: none;
  }
`

const StoplightMobileWarning = styled('div')`
  display: none;
  position: fixed;
  height: 100vh;
  font-weight: bold;
  font-size: 32px;
  text-align: center;
  align-items: center;
  padding: 0 24px;
  top: 0;

  @media screen and (max-width: 800px) {
    display: flex;
  }
`

export default function Api({
  location
}) {
  return (
    <MainLayout
      location={location}
      noFooter
      fullWidth
      permanentHeaderShadow
      padding={['No padding']}
    >
      <StoplightMobileWarning>
        Open the API Reference on desktop for the best experience.
      </StoplightMobileWarning>
      <StoplightWrapper>
        <StoplightProject
          projectId="cHJqOjY4NTM"
          router={typeof window === 'undefined' ? 'memory' : 'history'}
          basePath="/api-docs"
        />
      </StoplightWrapper>
    </MainLayout>
  )
}

export const queryNavigationItem = graphql`
  fragment ContentfulNavigationItem on ContentfulNavigationItem {
    itemType: __typename
    id
    title
    page {
      slug
    }
    externalUrl

    icon {
      file {
        url
      }
    }
	}
`

export const query = graphql`
  query($slug: String!) {
    contentfulNavigation(navigationId: {eq: "main"}) {
      items {
        ...ContentfulNavigationItem
        subNavigationItems {
          ...ContentfulNavigationItem
        }
      }
    }
    contentfulPage(slug: {eq: $slug}) {
      meta {
        pageTitle
        metaDescription
      }
    }
  }
`
